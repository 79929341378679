import { graphql, Link, useStaticQuery } from "gatsby";
import { getHref } from "../getHref";
import {
  menuItemCssClasses,
  MenuItemData,
  MenuItemWithSubpages,
} from "./MenuItemWithSubpages";

interface PageMenuItemData {
  heading: string | null;
  menuHeading: string | null;
  slug: string;
}

export interface EventPageMenuItemData extends PageMenuItemData {
  __typename: "ContentfulEventPage";
}

export interface RegularPageMenuItemData extends PageMenuItemData {
  __typename: "ContentfulRegularPage";
}

interface NavigationMenuData {
  contentfulShared: {
    menuItems: Array<
      EventPageMenuItemData | RegularPageMenuItemData | MenuItemData
    > | null;
  };
}

interface Props {
  hideNavigationMenu: () => void;
  locationPathname: string;
}

export const MenuItems = (props: Props) => {
  const navigationMenuData = useStaticQuery<NavigationMenuData>(graphql`
    {
      contentfulShared {
        menuItems {
          __typename
          ... on Node {
            ... on ContentfulEventPage {
              heading
              menuHeading
              slug
            }
            ... on ContentfulRegularPage {
              heading
              menuHeading
              slug
            }
            ... on ContentfulMenuItem {
              pageLink {
                __typename
                ... on ContentfulEventPage {
                  heading
                  menuHeading
                  slug
                }
                ... on ContentfulRegularPage {
                  heading
                  menuHeading
                  slug
                }
              }
              subpagesLinks {
                __typename
                ... on ContentfulEventPage {
                  heading
                  menuHeading
                  slug
                }
                ... on ContentfulRegularPage {
                  heading
                  menuHeading
                  slug
                }
              }
            }
          }
        }
      }
    }
  `);

  if (navigationMenuData.contentfulShared.menuItems === null) {
    return null;
  }

  const elementsArray = navigationMenuData.contentfulShared.menuItems.map(
    (menuItem) => {
      switch (menuItem.__typename) {
        case "ContentfulEventPage":
        case "ContentfulRegularPage":
          return (
            <Link
              className={menuItemCssClasses}
              key={menuItem.slug}
              to={getHref(menuItem)}
              onClick={(e) => {
                if (getHref(menuItem) === props.locationPathname) {
                  e.preventDefault();
                  props.hideNavigationMenu();
                }
              }}
            >
              {menuItem.menuHeading ?? menuItem.heading}
            </Link>
          );
        case "ContentfulMenuItem":
          return (
            <MenuItemWithSubpages
              hideNavigationMenu={props.hideNavigationMenu}
              key={menuItem.pageLink?.slug}
              locationPathname={props.locationPathname}
              menuItem={menuItem}
            />
          );
      }
    },
  );

  // Returning arrays isn't supported in functional components.
  return <>{elementsArray}</>;
};
