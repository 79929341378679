import { Link } from "gatsby";
import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { getHref } from "../getHref";
import CollapseSubpagesSvg from "./CollapseSubpages.svg";
import ExpandSubpagesSvg from "./ExpandSubpages.svg";
import { EventPageMenuItemData, RegularPageMenuItemData } from "./MenuItems";

export interface MenuItemData {
  __typename: "ContentfulMenuItem";
  pageLink: EventPageMenuItemData | RegularPageMenuItemData | null;
  subpagesLinks: Array<EventPageMenuItemData | RegularPageMenuItemData> | null;
}

interface Props {
  hideNavigationMenu: () => void;
  locationPathname: string;
  menuItem: MenuItemData;
}

const shared = "d-block text-light text-decoration-none bg-white-hover";
export const menuItemCssClasses = `${shared} h2 px-2 mt-4 mb-0`;

export const MenuItemWithSubpages = (props: Props) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    setShowSubMenu(false);
  }, [props.locationPathname]);

  return (
    <>
      <Link
        className={`${menuItemCssClasses} position-relative`}
        key={props.menuItem.pageLink?.slug}
        onClick={(e) => {
          if (getHref(props.menuItem.pageLink) === props.locationPathname) {
            e.preventDefault();
            props.hideNavigationMenu();
          }
        }}
        to={getHref(props.menuItem.pageLink)}
      >
        {props.menuItem.pageLink?.heading}
        {props.menuItem.subpagesLinks !== null && (
          <span
            className="subpages-button clickable"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowSubMenu(!showSubMenu);
            }}
            role="button"
          >
            {showSubMenu ? (
              <img
                alt="Fold ind"
                className="expand-collapse-icon"
                src={CollapseSubpagesSvg}
              />
            ) : (
              <img
                alt="Fold ud"
                className="expand-collapse-icon"
                src={ExpandSubpagesSvg}
              />
            )}
          </span>
        )}
      </Link>
      {hasLinksToSubpages(props) ? (
        <AnimateHeight
          duration={100}
          easing="ease-out"
          height={showSubMenu ? "auto" : 0}
        >
          getLinksToSubpages(props)
        </AnimateHeight>
      ) : (
        getLinksToSubpages(props)
      )}
    </>
  );
};

const hasLinksToSubpages = (props: React.PropsWithChildren<Props>): boolean => {
  if (props.menuItem.subpagesLinks === null) {
    return false;
  }

  if (props.menuItem.subpagesLinks.length === 0) {
    return false;
  }

  return true;
};

const getLinksToSubpages = (props: React.PropsWithChildren<Props>) => {
  return props.menuItem.subpagesLinks?.map((pageMenuItem, index) => (
    <Link
      className={`${shared} lead mb-3 ${index === 0 ? "mt-3" : ""}`}
      key={pageMenuItem.slug ?? undefined}
      onClick={(e) => {
        if (getHref(pageMenuItem) === props.locationPathname) {
          e.preventDefault();
          props.hideNavigationMenu();
        }
      }}
      to={getHref(pageMenuItem)}
    >
      {pageMenuItem.heading}
    </Link>
  ));
};
