import MenuButtonBurger from "./MenuButtonBurger.svg";
import MenuButtonCross from "./MenuButtonCross.svg";
import { MenuItems } from "./MenuItems";

interface Props {
  locationPathname: string;
  menuShown: boolean;
  setMenuShown: (showMenu: boolean) => void;
}

export const NavigationMenu = (props: Props) => {
  return (
    <div className={props.menuShown ? "navigation-menu-shown" : ""}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-23 text-end position-relative">
            <img
              alt="Show menu"
              className="navigation-menu-button navigation-menu-button-show clickable"
              id="menu-button"
              onClick={() => props.setMenuShown(!props.menuShown)}
              role="button"
              src={MenuButtonBurger}
            />
            <img
              alt="Hide menu"
              className="navigation-menu-button navigation-menu-button-hide clickable"
              onClick={() => props.setMenuShown(!props.menuShown)}
              role="button"
              src={MenuButtonCross}
            />
            <label
              className="navigation-menu-label darker-red clickable d-none d-sm-block lead position-absolute"
              htmlFor="menu-button"
              onClick={() => props.setMenuShown(!props.menuShown)}
            >
              Menu
            </label>
          </div>
        </div>
      </div>
      <nav className="navigation-menu mt-n5 bg-primary text-light text-center">
        <MenuItems
          hideNavigationMenu={() => props.setMenuShown(false)}
          locationPathname={props.locationPathname}
        />
      </nav>
    </div>
  );
};
