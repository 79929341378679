import {
  EventPageMenuItemData,
  RegularPageMenuItemData,
} from "./navigationMenu/MenuItems";

export const getHref = (
  menuItem: EventPageMenuItemData | RegularPageMenuItemData | null,
): string => {
  if (menuItem === null) {
    return "";
  }

  switch (menuItem.__typename) {
    case "ContentfulEventPage":
      return `/event/${menuItem.slug}/`;
    case "ContentfulRegularPage":
      return `/${menuItem.slug}/`;
  }
};
