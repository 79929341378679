import { PageProps } from "gatsby";
import { useEffect, useState } from "react";
import { NavigationMenu } from "./NavigationMenu";

interface PagePropsWithChildren extends Omit<PageProps, "children"> {
  children: React.ReactNode;
}

export const NavigationMenuWrapper = (props: PagePropsWithChildren) => {
  const [menuShown, setMenuShown] = useState(false);

  useEffect(() => {
    setMenuShown(false);
  }, [props.location.pathname]);

  return (
    <>
      <NavigationMenu
        locationPathname={props.location.pathname}
        menuShown={menuShown}
        setMenuShown={setMenuShown}
      />
      <div className={menuShown ? "max-vh-100 overflow-hidden" : ""}>
        {props.children}
      </div>
    </>
  );
};
